import React from "react"
import { Box } from "rebass"

const Content = () => (
  <Box mt={[32, 62]}>
    <h2>Understanding How Capacity Is Calculated in DynamoDB</h2>
    <p>
      DynamoDB is billed and measured on RCUs (Read Capacity Unit) and WCUs
      (Write Capacity Unit) basis. Both of these units are basically multipliers
      of item size that is going to be read or written to the table, rounded up
      to the next largest whole number.
    </p>
    <p>
      One read request unit represents one strongly consistent read request, or
      two eventually consistent read requests, for an item up to 4 KB in size.
    </p>
    <p>
      One write request unit represents one write for an item up to 1 KB in
      size. If you need to write an item that is larger than 1 KB, DynamoDB
      needs to consume additional write request units.
    </p>
    <p>
      Once you've calculated your WCU/RCUs, you can use{" "}
      <a href="/dynamodb-pricing-calculator/">
        our DynamoDB Pricing Calculator
      </a>{" "}
      to estimate your DynamoDB costs.
    </p>
  </Box>
)

export default Content
