import React from "react"
import { Box } from "rebass"

import FAQJsonLD from "@components/FAQJsonLD"

const questions = [
  {
    question: "What is the maximum size of an item in DynamoDB?",
    answer: "Maximum Size of DynamoDB Item is 400KB.",
  },
  {
    question: "Can DynamoDB store BLOB data?",
    answer:
      "Yes it can. However, when often accessed and manipulated, it can easily deplete provisioned read/write capacity units and cause your DynamoDB costs to skyrocket. In most cases, we recommend storing blob, such as images or PDFs in S3 and store only their URLs in DynamoDB.",
  },
  {
    question: "How does DynamoDB calculate item size?",
    answer: `Item\'s size is the sum of the attribute names and values. As DynamoDB is using UTF8 encoding, most chars use 1 byte but some less used characters, like language specific, use 2, while emojis can consume up to 4.<br/><ul>
    <li>Numbers use between 2 and 21 bytes.</li>
    <li>Binary and BinarySets are using 1 byte per 1 byte, obviously.</li>
    <li>Boolean is also 1 byte.</li>
    <li>Null is 1 byte too.</li>
    <li>Maps are 3 bytes + size of each key-value pair</li>
  </ul>For more info how it's calculated
  <a
    href="https://medium.com/@zaccharles/calculating-a-dynamodb-items-size-and-consumed-capacity-d1728942eb7c"
    rel="noopener noreferrer"
    target="_blank"
  >
    read this article
  </a>`,
  },
  {
    question: "How much data can DynamoDB store?",
    answer:
      "Theoretically, DynamoDB can store an infinite amount of data because there's no upper limit of data per table. There's a limit of 10GB of data per partition, however, you can have as many partitions as you want.",
  },
]

const FAQ = props => (
  <Box mt={[32, 62]}>
    <FAQJsonLD questions={questions} />
    <h2>Frequently Asked Questions</h2>

    {questions.map(question => (
      <>
        <h3>{question.question}</h3>
        <p dangerouslySetInnerHTML={{ __html: question.answer }} />
      </>
    ))}

    <h3>I've calculated WCU and RCU. How does this relate to $$?</h3>
    <p>
      Use our{" "}
      <a href="/dynamodb-pricing-calculator/">DynamoDB Pricing Calculator</a>
    </p>

    <h3>Is the item I paste in the calculator above being stored anywhere?</h3>
    <p>
      No. We're not sending that data anywhere, item size is calculated using
      client-side Javascript inside your browser.
    </p>
  </Box>
)

export default FAQ
