import React from "react"
import { FooterWithCTA, SEO, Layout, Container, Hero } from "@components"
import Content from "@components/pages/item-size-calculator/Content.js"
import Tool from "@components/pages/item-size-calculator/Tool.js"
import FAQ from "@components/pages/item-size-calculator/FAQ.js"

const ItemSizeCalculator = () => {
  return (
    <>
      <Layout>
        <SEO
          title="DynamoDB Capacity &amp; Item Size Calculator [Free Tool]"
          description="This free tool helps calculate DynamoDB capacity and item size."
          canonical="https://dynobase.dev/dynamodb-capacity-and-item-size-calculator/"
        />
        <Hero title="DynamoDB Capacity & Item Size Calculator" />
        <Container>
          <Tool />
          <Content />
          <FAQ />
        </Container>
        <FooterWithCTA />
      </Layout>
    </>
  )
}

export default ItemSizeCalculator
