import React, { useState, useEffect } from "react"
import { Box, Text } from "rebass"
import { Textarea } from "@rebass/forms"
import size, { write, read, storage } from "./utils"

const Tool = (props) => {
  const [value, setValue] = useState('{"name":"Dynobase"}')
  const [bytes, setBytes] = useState(0)
  const [rcu, setRcu] = useState(0)
  const [wcu, setWcu] = useState(0)
  const [err, setError] = useState(null)

  useEffect(() => {
    const obj = JSON.parse('{"name":"Dynobase"}')

    setBytes(size(obj))
    setWcu(write(obj))
    setRcu(read(obj))
    setError(null)
  }, [])

  return (
    <Box mt={62}>
      <Text
        textAlign="center"
        fontSize="50px"
        fontWeight="bold"
        lineHeight="1.5"
      >
        {bytes}/409,600 Bytes
      </Text>
      <Textarea
        placeholder="Paste your DynamoDB item here to get started"
        height="300px"
        mt={52}
        value={value}
        style={{
          border: err ? "2px solid red" : "2px solid rgb(213, 213, 213)",
          borderRadius: 10,
          padding: 10,
          outline: "none",
          fontFamily: "hack, sans-serif",
        }}
        onChange={(e) => {
          setValue(e.target.value.trim())

          try {
            const obj = JSON.parse(e.target.value.trim())

            setBytes(size(obj))
            setWcu(write(obj))
            setRcu(read(obj))
            setError(null)
          } catch (error) {
            console.warn(error)
            setError(error)
          }
        }}
      />

      {!err && (
        <>
          <div style={{ marginTop: 10 }}>
            This item will consume <b>{wcu} WCU</b> when written to the DynamoDB
          </div>
          <div>
            This item will consume <b>{rcu} RCU</b> when read with strong
            consistency
          </div>
        </>
      )}

      <div
        style={{
          fontFamily: "hack, sans-serif",
          color: "red",
        }}
      >
        {err && err.message}
      </div>
    </Box>
  )
}

export default Tool
